import React from "react"
import { Helmet } from "react-helmet"

import { Link, graphql } from "gatsby"
import Layout, { athenaTheme } from "../components/layout"
import styles from "./endlesss.module.scss"
import PreFooter from "../components/athena/preFooter"
import ScrollProgressBar from "../components/scrollProgressBar"

import ZeusLogo from "../images/endlesss/zeus-logo.svg"
import EndlesssLogo from "../images/endlesss/endlesss-logo.svg"
import Gradient from "../images/latest-news.gif"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
}

export const query = graphql`
  query {
    craft {
      entry(section: "endlesss") {
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_endlesss_endlesss_Entry {
          articleSubTitle
          mainTitle
          mainContent
          columnsTitle {
            ... on Craft_columnsTitle_BlockType {
              columnOneTitle
              columnTwoTitle
              columnThreeTitle
              columnFourTitle
            }
          }
          columnLinks {
            ... on Craft_columnLinks_BlockType {
              columnOneLink
              columnTwoLink
              columnThreeLink
              columnFourLink
            }
          }
          columnsText {
            ... on Craft_columnsText_BlockType {
              textColumnOne
              textColumnTwo
              textColumnThree
              textColumnFour
            }
          }
          columnsImages {
            ... on Craft_columnsImages_BlockType {
              columnOneImage {
                ... on Craft_athenaContent_Asset {
                  url
                  img
                  altText
                }
              }
              columnTwoImage {
                ... on Craft_athenaContent_Asset {
                  url
                  img
                  altText
                }
              }
              columnThreeImage {
                ... on Craft_athenaContent_Asset {
                  url
                  img
                  altText
                }
              }
              columnFourImage {
                ... on Craft_athenaContent_Asset {
                  url
                  img
                  altText
                }
              }
            }
          }
          relatedArticles {
            id
            title
            uri
            ... on Craft_athenaArticles_athenaArticleCustom_Entry {
              id
              coverImages {
                ... on Craft_coverImages_BlockType {
                  ratio_1x1 {
                    url
                  }
                  ratio_3x4 {
                    url
                  }
                  ratio_4x3 {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const EndlessPage = ({ data }) => {
  const entry = data.craft.entry
  const seo = entry.seomatic

  const title = entry.mainTitle
  const mainContent = entry.mainContent
  const columnsTitle = entry.columnsTitle[0]
  const columnsText = entry.columnsText[0]
  const columnLinks = entry.columnLinks[0]

  const columnsImage = entry.columnsImages[0]
  const columnsImageOne = columnsImage.columnOneImage[0].url
    const columnsImageOneAlt = columnsImage.columnOneImage[0].alt
  const columnsImageTwo = columnsImage.columnTwoImage[0].url
    const columnsImageTwoAlt = columnsImage.columnTwoImage[0].alt
  const columnsImageThree = columnsImage.columnThreeImage[0].url
    const columnsImageThreeAlt = columnsImage.columnThreeImage[0].alt
  const columnsImageFour = columnsImage.columnFourImage[0].url
    const columnsImageFourAlt = columnsImage.columnFourImage[0].alt

  const relatedArticlesOne = entry.relatedArticles[0]
    const relatedArticlesOneImage = relatedArticlesOne.coverImages[0].ratio_4x3[0].url
  const relatedArticlesTwo = entry.relatedArticles[1]
    const relatedArticlesTwoImage = relatedArticlesTwo.coverImages[0].ratio_3x4[0].url
  const relatedArticlesThree = entry.relatedArticles[2]
    const relatedArticlesThreeImage = relatedArticlesThree.coverImages[0].ratio_1x1[0].url

  const paraOne = columnsText.textColumnOne
  const paraTwo = columnsText.textColumnTwo
  const paraThree = columnsText.textColumnThree
  const paraFour = columnsText.textColumnFour

  return (
    <Layout seo={seo} mode="athena" theme={theme}>
      <Helmet>
        <body className={`athena ${styles.body}`} />
      </Helmet>
  <ScrollProgressBar />
        <div className="page-content-module">
          <div className={styles.hero}>
            <div className="wrapper">
              <div className="text-center">
                <div className={styles.title}>
                  <ZeusLogo className={styles.logos}/>
                  <img src={Gradient} alt="Gradient Circle" className={styles.gradient}/>
                  <EndlesssLogo className={styles.logos}/>
                </div>
                <h1 className="a-h-display">{title}</h1>
                <div
                  dangerouslySetInnerHTML={{__html: mainContent}}
                />
              </div>
            </div>
          </div>
          <div className={styles.pageContent}>
            <div className="wrapper">
              <div className={`columns is-tablet is-multiline`}>
                <div className={`${styles.column} column is-12-mobile is-6-tablet is-3-desktop`}>
                  <a href={columnLinks.columnOneLink}
                  className={`img-tile ${styles.columnOne}`}
                  target="_blank"
                  rel="noreferrer"
                  >
                    <div className={`mb-4 img-tile-image ${styles.imgContainer}`}>
                      <img src={columnsImageOne} alt={columnsImageOneAlt} />
                      <button>Join This Jam</button>
                    </div>
                      <h3 className="a-h-3 italic">
                        <span className="img-tile-title-underline">
                          {columnsTitle.columnOneTitle}
                        </span>
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{__html: paraOne}}
                      />
                    </a>
                </div>

                <div className={`${styles.column} column is-12-mobile is-6-tablet is-3-desktop`}>
                  <a
                  href={columnLinks.columnTwoLink}
                  className={`img-tile ${styles.columnTwo}`}
                  target="_blank"
                  rel="noreferrer">
                    <div className={`mb-4 img-tile-image ${styles.imgContainer}`}>
                      <img src={columnsImageTwo} alt={columnsImageTwoAlt} />
                      <button>Join This Jam</button>
                    </div>
                      <h3 className="a-h-3 italic">
                        <span className="img-tile-title-underline">
                          {columnsTitle.columnTwoTitle}
                        </span>
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{__html: paraTwo}}
                      />
                  </a>
                </div>

                <div className={`${styles.column} column is-12-mobile is-6-tablet is-3-desktop`}>
                  <a
                  href={columnLinks.columnThreeLink}
                  className={`img-tile ${styles.columnThree}`}
                  target="_blank" 
                  rel="noreferrer">
                    <div className={`mb-4 img-tile-image ${styles.imgContainer}`}>
                      <img src={columnsImageThree} alt={columnsImageThreeAlt} />
                      <button>Join This Jam</button>
                    </div>
                      <h3 className="a-h-3 italic">
                        <span className="img-tile-title-underline">
                          {columnsTitle.columnThreeTitle}
                        </span>
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{__html: paraThree}}
                      />
                  </a>
                </div>

                <div className={`${styles.column} column is-12-mobile is-6-tablet is-3-desktop`}>
                  <a 
                  href={columnLinks.columnFourLink}
                  className={`img-tile ${styles.columnFour}`}
                  target="_blank"
                  rel="noreferrer"
                  >
                    <div className={`mb-4 img-tile-image ${styles.imgContainer}`}>
                      <img src={columnsImageFour} alt={columnsImageFourAlt} />
                      <button>Join This Jam</button>
                    </div>
                      <h3 className="a-h-3 italic">
                        <span className="img-tile-title-underline">
                          {columnsTitle.columnFourTitle}
                        </span>
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{__html: paraFour}}
                      />
                  </a>
                </div>

                
                
                
              </div>
            </div>
          </div>
          
          <nav className={`wrapper ${styles.more}`}>
            <div className={styles.heading}>
              <h2 className={styles.joyHeading}>M<em>o</em>re <span>f</span>rom t<span>h</span>i<span>s</span> iss<span>u</span>e</h2>
            </div>
            <div className={styles.articles}>
              <div className="columns is-mobile is-tablet is-multiline">
                <div className={`column is-12-mobile is-6-tablet is-4-desktop`}>
                  <Link className="img-tile" to={`/${relatedArticlesOne.uri}`}>
                    <div className={`mb-4 img-tile-image ${styles.imgContainer}`}>
                      <img src={relatedArticlesOneImage} alt="Vocab Thumbnail" />
                      <h3 className="a-h-3 italic">
                        <span className="img-tile-title-underline">
                          {relatedArticlesOne.title}
                        </span>
                      </h3>
                    </div>
                  </Link>
                </div>
                <div className={`column is-12-mobile is-6-tablet is-4-desktop`}>
                  <Link className="img-tile" to={`/${relatedArticlesTwo.uri}`}>
                    <div className={`mb-4 img-tile-image ${styles.imgContainer}`}>
                      <img src={relatedArticlesTwoImage} alt="" />
                      <h3 className="a-h-3 italic">
                        <span className="img-tile-title-underline">
                          {relatedArticlesTwo.title}
                        </span>
                      </h3>
                      </div>
                  </Link>
                </div>
                <div className={`column is-12-mobile is-6-tablet is-4-desktop`}>
                  <Link className="img-tile" to={`/${relatedArticlesThree.uri}`}>
                    <div className={`mb-4 img-tile-image ${styles.imgContainer}`}>
                      <img src={relatedArticlesThreeImage} alt="" />
                      <h3 className="a-h-3 italic">
                        <span className="img-tile-title-underline">
                          {relatedArticlesThree.title}
                        </span>
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <PreFooter style={{ marginTop: 0}} />
    </Layout>
  )
}

export default EndlessPage
